<template>
  <div class="d-flex align-baseline flex-nowrap">
    <document-ref-icon
      :visible="showIcon"
      :icon-name="iconName"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
    />
    <span class="mr-1">{{$t('t.Number#')}}</span>
    <span class="mr-1">{{number}}</span>
    <slot name="doc-type" />
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon')
  },
  computed: {
    number () {
      return this.id ? this.cache?.doc?.number : this.$t('t.None')
    },
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    }
  },
  mixins: [ClientCache],
  props: {
    cacheType: Number,
    id: String,
    iconName: String,
    showIcon: Boolean,
    iconProps: Object,
    mixed: Boolean
  }
}
</script>

<style lang="stylus" scoped></style>
